import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from './scripts/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as skipLinks from './scripts/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as nav from '@elements/accessibility-nav';
app.modules.accessibilityNav = nav.initInScope;

import * as toggleNav from './scripts/toggle-nav';
app.modules.toggleNav = toggleNav.initInScope;

import * as affixNavbar from './scripts/affix-navbar';
app.affixNavbar = affixNavbar.init;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as affixButton from './scripts/affix-button';
app.modules.affixButton = affixButton.initInScope;

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as slider from '@elements/slider';
app.modules.gridSlider = slider.createInitInScope('.js-grid-slider', {
    arrows: false,
    infinite: false,
    slidesToShow: 2.275,
    slidesToScroll: 1,
    swipeToSlide: true,
    dotsClass: 'sr-only',
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 1.8
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.275
            }
        }
    ]
});

app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    dots: true, /*should always be true; hide with css (accessibility)*/
    dotsClass: 'sr-only',
    slidesToShow: matchMedia('(max-width: 767px)').matches ? 1 : 3,
    swipeToSlide: true,
    infinite: false,
    slidesToScroll: 1,
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-prev"><span class="icon icon-arrow-right icon-rotate-180"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-next"><span class="icon icon-arrow-right"></span></button>'
});

app.modules.slider = slider.createInitInScope('.js-slider', {
    dots: true, /*should always be true; hide with css (accessibility)*/
    dotsClass: matchMedia('(max-width: 767px)').matches ? 'slick-dots' : 'sr-only',
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: true,
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-prev"><span class="icon icon-arrow-right icon-rotate-180"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-next"><span class="icon icon-arrow-right"></span></button>'
});

app.modules.imgTextSlider = slider.createInitInScope('.js-img-text-slider', {
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dotsClass: 'sr-only'
});

import * as transformIn from './scripts/transform-in';
app.modules.transformIn = transformIn.initInScope;

import * as fadeIn from './scripts/fade-in';
app.modules.fadeIn = fadeIn.initInScope;

import * as contentConnector from './scripts/content-connector';
app.modules.contentConnector = contentConnector.initInScope;

import * as responsiveTable from './scripts/responsive-table';
app.modules.responsiveTable = responsiveTable.initInScope;

import * as parsley from './scripts/parsley';
app.modules.parsley = parsley.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });
};

import * as scrollSpy from './scripts/scroll-spy';
app.scrollSpy = scrollSpy.init;

import * as scrollTo from './scripts/scroll-to';
app.modules.scrollTo = scrollTo.initInScope;

import * as matchmediaViewer from "./scripts/matchmedia-viewer";
app.matchmediaViewer = matchmediaViewer.init;
app.modules.matchmediaViewer = matchmediaViewer.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

localStorage.setItem('cookie-permissions', '')
import { showCookieBar } from "@elements/cookie-permissions";
showCookieBar();

import * as overlayToggle from './scripts/overlay-toggle';
app.modules.overlayToggle = overlayToggle.initInScope;

import * as fileUpload from './scripts/file-upload';
app.modules.fileUpload = fileUpload.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as countUp from './scripts/countup';
app.modules.countUp = countUp.initInScope;

import * as friendlyRecaptcha from './scripts/friendlyRecaptcha';
app.modules.friendlyRecaptcha = friendlyRecaptcha.initInScope;

import loadScript from "@elements/load-script";
app.pinIt = () => loadScript('//assets.pinterest.com/js/pinit.js');

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);