"use strict";

export function initInScope($scope) {
    $scope.find('.js-overlay-toggle').on('click', function (evt) {
       evt.preventDefault();

       let $target = $($(this).data('target'));

       if(!$target.hasClass('is-open')) {
           $target.addClass('is-open');
           $(this).addClass('is-active');

           //close navbar
           // if($(this).closest('.navbar').hasClass('is-open')) {
           //     $(this).closest('.navbar').removeClass('is-open');
           // }

       } else {
           $target.removeClass('is-open');
           $(this).removeClass('is-active');
       }
    });
}