"use strict";

let id = 0;

function addTocEntry(text, anchorId, accesskey) {
    $tocList.append('<li><button class="sr-only sr-only-focusable" accesskey="' + accesskey + '" data-href="#' + anchorId + '">' + text + '</button></li>');
}

let $tocList;
export function init() {
    $tocList = $('.js-toc__list');
    if (!$tocList || !$tocList.length) {
        console.warn('can\'t render Table of content. No js-toc__list found');
    }

    $tocList.on('click', 'button', function () {
        let $target = $($(this).attr('data-href'));

        if (!$target || !$target.length) {
            console.warn('Could not jump to "', $(this).attr('data-href'), '". Element was not found');
            return;
        }

        let $tabPaneParent = $target.closest('.tab-pane:not(.active)');
        let $collapseParent = $target.closest('.collapse:not(.in)');

        if ($tabPaneParent && $tabPaneParent.length) {
            let targetSelector = '#' + $tabPaneParent.attr('id');
            let $trigger = $('[data-toggle="tab"]').filter('[data-target="' + targetSelector + '"],[href="' + targetSelector + '"]');
            $trigger.tab('show');
            window.scrollTo(window.scrollX, $tabPaneParent.parent().offset().top);
        } else if ($collapseParent && $collapseParent.length) {
            $collapseParent.collapse('show');
            window.scrollTo(window.scrollX, $collapseParent.parent().offset().top);
        } else {
            window.scrollTo(window.scrollX, $target[0].offsetTop -100);
            $target.focus();
        }


    });
};

export function initInScope ($scope) {
    $scope.find('.js-toc__title').each(function () {
        let $title = $(this);
        let anchorId = 'toc-entry-target-' + id;
        $title.attr('id', 'toc-entry-target-' + id);
        if (!isFocusable($title)) {
            $title.attr('tabindex', '-1');
        }
        id++;
        addTocEntry($title.text(), anchorId,+id+3);
    });
}

function isFocusable( $el ) {
    let tabIndex = +$el.attr( "tabindex" );
    tabIndex = isNaN( tabIndex ) ? -1 : tabIndex;
    return $el.is( ":input, a[href], button[data-href], area[href], iframe" ) || tabIndex > -1;
}


