"use strict";

let didScroll;

export function initInScope ($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        let $button = $scope.find('.js-affix-btn'),
            offset = $button.data('affix-offset');

        $(window).scroll(function () {
            didScroll = true;
        });

        requestAnimationFrame(function animationFrameHandler() {
            if (didScroll) {
                if($(window).scrollTop() > offset) {
                    $button.addClass('is-affix');
                } else if($(window).scrollTop() <= offset) {
                    $button.removeClass('is-affix');
                }

                didScroll = false;
            }
            requestAnimationFrame(animationFrameHandler);
        });
    }
}