"use strict";

import videojs from 'video.js';

let currentId = 1;
export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');
    let defaultOptions = {};
    
    $videos.each(function () {
        let id = $(this).prop('id'),
            options = $.extend({}, defaultOptions);

        if (!id) {
            id = 'video-js-id-' + currentId++;
            $(this).prop('id', id);
        }


        // if($(this).data('autoplay') === true) {
        //     options = $.extend(true, {}, defaultOptions, {
        //         autoplay: true
        //     })
        // }
        let video = videojs(id, options);

        if ($(this).data('tracking-category')) {
            let category = $(this).data('tracking-category'),
                action = $(this).data('tracking-action');

            video.on("play", function(){
                //send event on first play
                if (video.currentTime() === 0) {
                    dataLayerPush(category, action, 'play');
                }
            });
            video.on("pause", function(evt){
                dataLayerPush(category, action, 'pause '+video.currentTime());
            });
            video.on('ended', function (evt) {
                dataLayerPush(category, action, 'end '+video.currentTime());
            });
        }
    });
}

function dataLayerPush(category, action, label) {
    if(window.dataLayer) {
        dataLayer.push({
            'event': 'genericGtmEvent',
            'genericEventCategory': category,
            'genericEventAction': action,
            'genericEventLabel': label
        });
    }
}