"use strict";

export function initInScope($scope) {
    /* add table-styling */
    let table = $scope.find('.wysiwyg table');

    table.removeAttr('cellspacing').removeAttr('cellpadding').removeAttr('border').removeAttr('width');
    table.wrap('<div class="table-responsive"></div>');
    table.addClass('table');
}
