"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {CountUp} from 'countup.js';

export function initInScope($scope) {
    let counts = $scope.find('.js-count-up');
    onEnterViewPort(counts, function() {
        counts.each(function() {
            let $this = $(this);


            let counter = new CountUp(this, $this.data('count-value'), {
                separator: '.'
            });

            if (!counter.error) {
                counter.start();
            } else {
                console.error(counter.error);
            }
        });
    });
}
