"use strict";

export function initInScope ($scope) {
    $scope.find('.js-nav__item > a, .js-nav__item__toggle').on('click', function (evt) {
        evt.preventDefault();
        let $navItem = $(this).closest('.js-nav__item');

        if(!$navItem.hasClass('is-open')) {
            if(matchMedia('(min-width: 768px)').matches) {
                $(this).closest('.navbar').find('.js-nav__item').removeClass('is-open');
            }

            $navItem.addClass('is-open');
        } else {
            $navItem.removeClass(('is-open'))
        }
    });

    $scope.find('.js-toggle-nav').on('click', function (evt) {
        evt.preventDefault();

        let $mainNav = $(this).closest('.navbar');

        if(!$mainNav.hasClass('is-open')) {
            $mainNav.addClass('is-open');
        } else {
            $mainNav.removeClass('is-open');
        }
    });
}