"use strict";

export function init () {
    //init scrollspy
    $('body').scrollspy({ target: '#scrollSpy' });

    let id = 0;

    //add nav items
    function addNavItem (text, anchorId) {
        $('.js-inline-nav__list').append('<a class="list-group-item scroll-spy__item js-smooth-scroll" title="'+ text +'" href="#' + anchorId + '">' +
            ' <span class="icon icon-oval text-secondary"></span></a>');
    }

    $('.js-inline-nav__item').each(function () {
        let $this = $(this),
            title = $this.data('nav-name'),
            anchorId = 'inline-nav-item-' + id;

        $this.attr('id', 'inline-nav-item-' + id);
        id++;
        addNavItem(title,anchorId);

        $('body').scrollspy('refresh');
    });
}