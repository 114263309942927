"use strict";

let $navbar,
    $navbarContainer,
    offset,
    didScroll,
    defaultTopPosition;

function scrollHandler() {
    if($(window).scrollTop() > offset) {
        $navbar.addClass('is-affix');
    } else if($(window).scrollTop() <= offset) {
        $navbar.removeClass('is-affix');
    }
}

export function init() {
    $navbar = $('.js-affix-nav-bar');
    $navbarContainer = $('.js-affix-nav-bar-container');
    defaultTopPosition = $navbarContainer.offset().top;

    if(matchMedia('(max-width: 767px)').matches) {
        offset = $navbarContainer.offset().top;

        if($(window).scrollTop() > offset) {
            $navbar.addClass('is-affix');
        }
    } else {
        offset = 20;

        if($(window).scrollTop() > offset) {
            $navbar.addClass('is-affix');
        }
    }

    $(window).scroll(function () {
        didScroll = true;
    });

    requestAnimationFrame(function animationFrameHandler() {
        if (didScroll) {
           scrollHandler();

            didScroll = false;
        }
        requestAnimationFrame(animationFrameHandler);
    });
}