"use strict";

export function initInScope($scope) {
    $scope.find('.js-fileupload__input').change(function(){
        let $this = $(this),
            fileUpload = $this.closest('.js-fileupload');

        if($this[0].files.length > 0) {
            let title = $.map($this[0].files, function (file) {
                return file.name
            }).join(', ');

            fileUpload.find('.js-fileupload__text').text(title);

            if(/(\.jpg|\.png|\.jepg|\.pdf|\.docx|\.zip)/g.test($this.val())){
                fileUpload.removeClass('has-error');
            }else{
                fileUpload.addClass('has-error');
            }
        } else {
            fileUpload.find('.js-fileupload__text').text('');
        }
    });
}