"use strict";
import $ from 'jquery'
import { default as throwError, throwWarning } from "@elements/throw-error";
import {  getConfigValue } from '@elements/config-utils';
import loadJQueryUi from '@elements/load-jquery-ui';
import {translate} from '@elements/translations';

const errorExampleDate ='1970-10-20';

export function initInScope ($scope) {
    let $forms = $scope.find('.js-parsley');
    loadParsley().then(function () {

        // checks file size of every single selected file
        if (!Parsley.hasValidator('maxFileSize')) {
            Parsley.addValidator('maxFileSize', {
                validateString: function(_value, maxSize, parsleyInstance) {
                    let files = parsleyInstance.$element[0].files;
                    for(let x = 0; x < files.length; x++) {
                        if(files[x].size >  maxSize * 1024) {
                            return false;
                        }
                    }
                },
                messages: {
                    de: translate('maxFileSizeError') + ' %s kb.',
                    en: translate('maxFileSizeError') + ' %s kb.'
                }
            });
        }

        if (!Parsley.hasValidator('fileAccept')) {
            Parsley.addValidator('fileAccept', {
                validateString: function(value, requirements) {
                    let fileAcceptArray = requirements.split(',');
                    let fileExtension = value.split('.').pop();
                    let isInArray = fileAcceptArray.filter(item => item.includes(fileExtension.toLowerCase()));

                    if(isInArray.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
                messages: {
                    de: translate('fileAccept') + ' %s',
                    en: translate('fileAccept') + ' %s'
                }
            });
        }



        // checks if the input value is a valid date due to the local date format of jQuery UI datepicker
        if (!Parsley.hasValidator('localDate')) {
            Parsley.addValidator('localDate', {
                validateString: function(value) {
                    // load datepicker
                    if (value) {
                        // return promise so it rejects if loading fails
                        return loadJQueryUi().then(function () {
                            let datepickerLocalData = $.datepicker.regional[_config.lang || ''];
                            if (!datepickerLocalData) {
                                datepickerLocalData = $.datepicker.regional[''];
                            }

                            const dateFormat = datepickerLocalData.dateFormat;

                            // check if date is ok
                            try {
                                // the datepicker will throw an error if the date is not valid/not parsable
                                $.datepicker.parseDate(dateFormat, value);
                            } catch (error) {
                                // the error message is stored in the _translations-object
                                let errorMsg = translate('parsley.error.invalid-date-format');
                                let localizedFormat;
                                let formattedExampleDate = $.datepicker.formatDate(dateFormat, new Date(errorExampleDate));

                                // "yy" means four digit year
                                localizedFormat = dateFormat.replace('yy', 'yyyy');

                                // translate the abbreviations if the language is german
                                if (_config.lang == 'de') {
                                    localizedFormat = localizedFormat.replace(/y/gi, 'J')
                                        .replace(/d/gi, 'T')
                                        .replace(/m/gi, 'M');
                                }

                                // replace the placeholder for the format-string in the error message
                                errorMsg = errorMsg.replace(/\[format\]/i, localizedFormat);

                                // replace the placeholder for the date example in the error message
                                errorMsg = errorMsg.replace(/\[date\]/i, formattedExampleDate);

                                // throw an error with the customized error message, so the field is marked invalid
                                throw(errorMsg);
                            }
                        });

                    }
                }
            });
        }

        $forms.each((i, form) => {
            let $form = $(form);
            if($form.is('form')){
                $forms.parsley(options);
            } else {
                throwWarning('Element with Class .js-parsley is not Type FORM: ', $form)
                $form.find(':input[type=submit]').on('click', () => {
                    isValid($form);
                })
            }
        })
    });
}

function extendParsleyAccessibility(){
    window.Parsley.on('field:error', function (obj) {
        let parsleyId = obj.__id__;
        let fieldClass = obj.__class__;
        let $elements = obj.$elements || [obj.$element];

        if (fieldClass === 'ParsleyFieldMultiple') {
            parsleyId = 'multiple-' + obj.$element.data('parsley-multiple');
        }

        $elements.forEach(element => {
            $(element)
                .addClass('is-invalid')
                .removeClass('is-valid')
                .attr({
                    'aria-invalid': "true",
                    'aria-describedby': 'parsley-id-' + parsleyId
                });
        });
    });

    window.Parsley.on('field:success', function (obj) {
        let $elements = obj.$elements || [obj.$element];

        $elements.forEach(element => {
            $(element)
                .addClass('is-valid')
                .removeClass('is-invalid')
                .removeAttr('aria-invalid aria-describedby');
        });
    });
}


export const options = {
    successClass: 'has-success is-valid',
    errorClass: 'has-error is-invalid',
    classHandler: function ({$element}) {
        return $element.closest('.form-group, .js-parsley__form-group');
    },
    errorsContainer: function ({$element}) {
        let errorContainer = $element.closest('.form-group, .js-parsley__form-group').find('.form-errors, .js-parsley__form-errors');
        if (errorContainer && errorContainer.length > 0) {
            return errorContainer;
        }
    },
    // nur felder validieren die sichtbar sind
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden"
};

let promise;
export function loadParsley() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function (resolve, reject) {
        import('parsleyjs').then(function () {
            extendParsleyAccessibility();
            if (getConfigValue('lang') && getConfigValue('lang') !== 'en') {
                import(/* webpackChunkName: "parsley-lang-package-" */'parsleyjs/dist/i18n/' + getConfigValue('lang') + '.js').then(function () {
                    resolve();
                }).catch(function (reason) {
                    /*fallback if there is no package*/
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (reason) {
            reject();
        });
    });

    return promise;
}

export function isParsleyForm ($form) {
    return $form.is('.js-parsley');
}

// make sure parsley is loaded if you call this function initially (e.g. wrap the call in a loadParsley.then())
export function isValid($form) {
    if(!$.fn.parsley){
        throwError(`Trying to Validate with Parsley, but Parsley is not Loaded! Try to wrap the call in a loadParsley.then().`);
        return
    }
    if($form.length > 1){
        throwWarning('isValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $form)
        $form = $form.eq(0);
    }

    if($form.is('form')){
        if (!$form.data('Parsley')) {
            /* if the form is not initialized */
            $form.parsley(options);
        }
        return $form.data('Parsley').validate();
    }else{
        throwWarning(`Using parsley without form.`);
        let $inputs = $form.find(':input'),
            isValid = true;
        $inputs.each(function () {
            if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                isValid = false
            }
        });
        return isValid
    }
}


export function whenValid($scope) {
    return new Promise((resolve) => {
        let isValid = true;

        if($scope.length > 1){
            throwWarning('whenValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $scope)
            $scope = $scope.eq(0);
        }

        return loadParsley().then(() => {
            if($scope.is('form')){
                if (!$scope.data('Parsley')) {
                    /* if the form is not initialized */
                    $scope.parsley(options);
                }
                isValid = $scope.data('Parsley').validate();
            }else{
                throwWarning(`Using parsley without form.`);
                $scope.find(':input').each(function () {
                    if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                        isValid = false
                    }
                });
            }

            resolve(isValid)
        })
    })
}
