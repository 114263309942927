'use strict'
import { WidgetInstance } from "friendly-challenge";

export function initInScope($scope) {
    let $recaptchaForm = $scope.find('.js-friendly-recaptcha');

    $recaptchaForm.each(function (form) {
        let $submitBtn = $(this).find('.btn:submit');
        let $recaptcha = $(this).find('.js-friendly-recaptcha__captcha');

        if(!($submitBtn).hasClass('recaptcha-finished')) {
            $submitBtn.attr('disabled','disabled');
        }

        window.doneCallback = doneCallback;
        function doneCallback(solution) {
            $submitBtn.addClass('recaptcha-finished');
            $submitBtn.removeAttr('disabled');
        }

        let options = {
            doneCallback: doneCallback
        }

        let widget = new WidgetInstance($recaptcha[0], options);

        $(form).on("focusin", function () {
            widget.start();
        });
    })
}