"use strict";

export function init() {
    let $skiplinks = $('.js-skip-links');
    console.log($skiplinks);
    $skiplinks.on('click', function () {
        let $target = $($(this).attr('data-href'));
        window.scrollTo(window.scrollX, $target[0].offsetTop);
        $target.focus();
    });
}
